
















import {
  CustomForm,
  CustomFormElementType,
  CustomFormElement,
  CustomFormStep,
} from "@/models/custom-form.model";
import { User } from "@/models/user.model";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, VModel } from "vue-property-decorator";
import { namespace } from "vuex-class";
import BaseForm from "../../BaseForm.vue";

const registrationModule = namespace("Registration");
const usersModule = namespace("Users");
const authModule = namespace("Auth");

@Component({
  components: { BaseForm },
})
export default class UserDetailForm extends Vue {
  isLoading = true;
  test = {};

  elementTypesToSkip = [CustomFormElementType.static];

  @VModel()
  formData!: unknown;

  @Prop()
  form!: string;

  @Prop()
  magicLink!: string;

  @Prop()
  language!: string;

  @authModule.Getter("isAdmin")
  isAdmin!: boolean;

  @usersModule.Getter("current")
  user!: User;

  @registrationModule.Getter("form")
  formConfig!: { [key: string]: CustomForm };

  @registrationModule.Action("fetchById")
  fetchForm!: (id: string) => Promise<void>;

  get magicLinkWithRedirect() {
    return `${this.magicLink}&redirect=${this.redirectForForm}`;
  }

  get redirectForForm() {
    if (this.form.startsWith("form")) {
      return `form:${this.form.substr(this.form.length - 1, 1)}`;
    }
    return "RegistrationForm";
  }

  get formConfigTransformed() {
    const newFormConfig = new CustomForm(false, "", "", [
      new CustomFormStep("", []),
    ]);
    const elements: CustomFormElement[] = [];
    for (const step of this.formConfig[this.locale].steps) {
      for (const element of step.elements) {
        if (this.elementTypesToSkip.includes(element.type)) {
          continue;
        }
        newFormConfig.steps[0].elements.push(element);
      }
    }
    return newFormConfig;
  }

  get locale() {
    return this.language || this.$i18n.locale;
  }

  async mounted() {
    this.isLoading = true;
    await this.fetchForm(this.form);
    this.isLoading = false;
  }
}
