


































































import { CustomForm } from "@/models/custom-form.model";
import { User } from "@/models/user.model";
import Vue from "vue";
import Component from "vue-class-component";
import Vuetable from "vuetable-2";
import { namespace } from "vuex-class";
import BaseForm from "../../BaseForm.vue";
import UserDetailForm from "./UserDetailForm.vue";
import UserLogs from "./UserLogs.vue";

const registrationModule = namespace("Registration");
const usersModule = namespace("Users");
const authModule = namespace("Auth");
const mailsModule = namespace("Mails");

@Component({
  components: { BaseForm, UserLogs, Vuetable, UserDetailForm },
})
export default class UserDetail extends Vue {
  isLoading = false;

  fields = [
    {
      name: "messageid",
      title: "id",
    },
    {
      name: "from",
      title: "from",
    },
    {
      name: "subject",
      title: "subject",
    },
    {
      name: "status",
      title: "status",
    },
    {
      name: "receivedat",
      title: "received at",
    },
  ];

  @authModule.Getter("isAdmin")
  isAdmin!: boolean;

  @usersModule.Getter("current")
  user!: User;

  @usersModule.Getter("all")
  users!: User[];

  @mailsModule.Getter("filteredMails")
  mails!: any[];

  @registrationModule.Getter("form")
  form!: CustomForm;

  @usersModule.Action("setHasPaid")
  setHasPaid!: (id: string) => Promise<void>;

  @usersModule.Action("setHasNotPaid")
  setHasNotPaid!: (id: string) => Promise<void>;

  @usersModule.Action("fetchById")
  fetchById!: (id: string) => Promise<void>;

  @usersModule.Action("create")
  create!: (user: User) => Promise<void>;

  @usersModule.Action("update")
  update!: (user: User) => Promise<void>;

  @registrationModule.Action("fetchParsedById")
  fetchForm!: (id: string) => Promise<void>;

  get isCreate() {
    return !this.$route.query.id;
  }

  get magicLink() {
    return `/authentication?email=${this.base64Email}&access_code=${this.base64AccessCode}`;
  }

  get base64Email() {
    return btoa(this.user.email as string);
  }

  get base64AccessCode() {
    return btoa(this.user.access_code as string);
  }

  get hasPaid() {
    return this.user.has_paid;
  }

  get formConfig() {
    return [
      {
        title: "General information",
        elements: [
          {
            field: "first_name",
            label: "first_name",
            type: "text",
            sizes: { xs: 12, sm: 6, md: 6, lg: 6 },
          },
          {
            field: "last_name",
            label: "last_name",
            type: "text",
            sizes: { xs: 12, sm: 6, md: 6, lg: 6 },
          },
          {
            field: "email",
            label: "email",
            type: "text",
            rules: "required",
            sizes: { xs: 12, sm: 6, md: 6, lg: 6 },
          },
          {
            field: "role",
            label: "role",
            type: "dropdown",
            values: this.isAdmin
              ? ["admin", "moderator", "user"]
              : ["moderator", "user"],
            sizes: { xs: 12, sm: 6, md: 6, lg: 6 },
          },
          {
            field: "address",
            label: "address",
            type: "text",
            sizes: { xs: 12, sm: 6, md: 6, lg: 6 },
          },
          {
            field: "postal_code",
            label: "postal_code",
            type: "text",
            sizes: { xs: 12, sm: 6, md: 6, lg: 6 },
          },
          {
            field: "city",
            label: "city",
            type: "text",
            sizes: { xs: 12, sm: 6, md: 6, lg: 6 },
          },
          {
            field: "country",
            label: "country",
            type: "text",
            sizes: { xs: 12, sm: 6, md: 6, lg: 6 },
          },
          {
            field: "access_code",
            label: "access_code",
            type: "text",
            rules: "required",
            sizes: { xs: 12, sm: 6, md: 6, lg: 6 },
          },
          {
            field: "preferred_livestream",
            label: "preferred_livestream",
            type: "text",
            sizes: { xs: 12, sm: 6, md: 6, lg: 6 },
          },
          {
            field: "referral_token",
            label: "referral_token",
            type: "text",
            sizes: { xs: 12, sm: 6, md: 6, lg: 6 },
          },
          {
            field: "group",
            label: "group",
            type: "text",
            sizes: { xs: 12, sm: 6, md: 6, lg: 6 },
          },
          {
            field: "remarks",
            label: "Remarks",
            type: "textarea",
            sizes: { xs: 12, sm: 12, md: 12, lg: 12 },
          },
        ],
      },
    ];
  }

  async doUserHasPaid() {
    this.isLoading = true;
    await this.setHasPaid(this.user.id);
    this.isLoading = false;
  }

  async doUserHasNotPaid() {
    this.isLoading = true;
    await this.setHasNotPaid(this.user.id);
    this.isLoading = false;
  }

  async doFetch() {
    if (!this.$route.query.id) {
      return;
    }
    this.isLoading = true;
    await this.fetchById(this.$route.query.id as string);
    await this.fetchMails();
    if (!this.user.additional_info) {
      this.user.additional_info = {};
    }
    if (!this.user.additional_info_form1) {
      this.user.additional_info_form1 = {};
    }
    if (!this.user.additional_info_form2) {
      this.user.additional_info_form2 = {};
    }
    if (!this.user.additional_info_form3) {
      this.user.additional_info_form3 = {};
    }
    if (!this.user.additional_info_form4) {
      this.user.additional_info_form4 = {};
    }
    this.isLoading = false;
  }

  async fetchMails() {
    this.isLoading = true;
    await this.$store.dispatch("Mails/fetchByRecipient", this.user?.email);
    this.isLoading = false;
  }

  async doSave() {
    this.isLoading = true;
    const isValid = await this.$validator.validateAll();

    if (!isValid) {
      this.isLoading = false;
      return;
    }

    try {
      if (this.isCreate) {
        await this.create(this.user);
      } else {
        await this.update(this.user);
      }
      this.$root.$bvToast.toast("User saved!", { variant: "success" });
    } catch (e) {
      this.$root.$bvToast.toast(
        `There was an error saving the user. ${(e as any).message}`,
        { variant: "danger" }
      );
    }
    this.isLoading = false;
  }

  async created() {
    await this.doFetch();
  }
}
